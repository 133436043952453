import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/nova/theme.css";
import React, { Suspense, useLayoutEffect } from "react";
import { withCookies } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import PrivateRoute from "./components/Authentication/VerifyLogin.tsx";
import asyncComponent from "./components/GenericComponents/AsyncComponent.jsx";
import NotFoundPage from "./components/NotFoundpage.jsx";
import ContextProvider from "./context/AppContext.js"; // Import the ContextProvider
import { CartProvider } from "./context/CartContext.js";
import "./index.css";
import "./layout/layout.scss";
import appRoutes from "./routes/routes.ts";
import { getCookie } from "./service/get-user.js";
import { ErrorBoundary } from "./views/ErrorBoundary.tsx";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title
);

// const CustomSpinner = asyncComponent(() =>
//   import("./components/GenericComponents/Spinner").then(
//     (module) => module.default
//   )
// );

const ShopHome = asyncComponent(() =>
  import("./components/Shop/Home/Main.tsx").then((module) => module.default)
);

const ProductDetails = asyncComponent(() =>
  import("./components/Shop/ProductDetails/ProductDetailsIndex.jsx").then(
    (module) => module.default
  )
);

const loggedInUser = getCookie("loggedInUser");
const App = ({ cookies }) => {
  useLayoutEffect(() => {
    let sub = true;

    if (sub) {
      // Redirect to login page if user is not logged in
      if (loggedInUser === undefined || loggedInUser === null) {
        <Navigate to="/" />;
      } else {
        <Navigate to="/dashboard" />;
      }
    }

    return () => {
      sub = false;
    };
  }, []);

  const FallBack = () => (
    <div className="w-screen h-screen flex items-center justify-center">
      <div className="spinner-border text-primary" role="status"></div>
    </div>
  );

  return (
    <div>
      <ErrorBoundary>
        <ContextProvider>
          <CartProvider>
            <Suspense fallback={<FallBack />}>
              <div className="App">
                <div className="app-routes" style={{ position: "relative" }}>
                  <HashRouter>
                    <Routes>
                      {/* Public routes */}
                      <Route
                        path="/"
                        element={<ShopHome cookies={cookies} />}
                      />
                      <Route
                        path="/product-details/:productId"
                        element={<ProductDetails cookies={cookies} />}
                      />

                      {/* Private routes */}
                      {appRoutes.map(
                        (
                          { path, isPrivateRoute, isNotCustomer, Component },
                          index
                        ) => (
                          <Route
                            key={index}
                            path={path}
                            element={
                              isPrivateRoute ? (
                                <PrivateRoute
                                  isPrivateRoute={isPrivateRoute}
                                  isNotCustomer={isNotCustomer}
                                  component={Component}
                                />
                              ) : (
                                <Component />
                              )
                            }
                          />
                        )
                      )}

                      {/* Handle 404 */}
                      <Route path="*" Component={NotFoundPage} />
                    </Routes>
                  </HashRouter>
                </div>
              </div>
            </Suspense>
          </CartProvider>
        </ContextProvider>
      </ErrorBoundary>
    </div>
  );
};

export default withCookies(App);
