import {
  ChevronLeft,
  ChevronRight,
  Filter,
  MoreHorizontal,
  Plus,
  Search,
} from "lucide-react";
import React, { lazy, useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";

import { Spinner } from "@chevtek/react-spinners";
import { debounce } from "@mui/material";
import { sendPostToServer } from "../../service/apiService.ts";
import { cn, formatCurrency } from "../../service/CommonComponent.js";
import { loggedInUser } from "../../service/get-user.js";
import { Product } from "../../types/inventory.ts";

const InventoryForm = lazy(
  () => import("../../components/InventoryManagement/InventoryForm.tsx")
);

interface FilterState {
  business: string | null;
  measurement: string | null;
  minPrice: string;
  maxPrice: string;
  minStock: string;
  maxStock: string;
}

const ITEMS_PER_PAGE = 10;

function Inventory() {
  const [searchTerm, setSearchTerm] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<FilterState>({
    business: null,
    measurement: null,
    minPrice: "",
    maxPrice: "",
    minStock: "",
    maxStock: "",
  });
  const [businesses, setBusinesses] = useState<string[]>([]);
  const [measurements, setMeasurements] = useState<string[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [showActionMenu, setShowActionMenu] = useState<string | null>(null);

  const fetchInventory = useCallback(
    async (page: number = 1, search: string = "") => {
      setIsLoading(true);
      try {
        const postObject = JSON.stringify({
          auth: loggedInUser(),
          pagination: {
            page,
            limit: ITEMS_PER_PAGE,
          },
          search: search.trim(),
          filters: {
            business: filters.business,
            measurement: filters.measurement,
            price: {
              min: filters.minPrice ? parseFloat(filters.minPrice) : null,
              max: filters.maxPrice ? parseFloat(filters.maxPrice) : null,
            },
            stock: {
              min: filters.minStock ? parseInt(filters.minStock) : null,
              max: filters.maxStock ? parseInt(filters.maxStock) : null,
            },
          },
        });

        const response = await sendPostToServer(
          "SalesManagement",
          "fetchInventory",
          postObject
        );

        if (response.returnCode === 200) {
          console.log(response);

          const data = response.returnData.map((item: any) => ({
            id: item.id,
            name: item.product,
            barcode: item.batch_no,
            business: item.business,
            unit: item.unit,
            measurement_type: item.measurement,
            purchase_price: item.purchase_price,
            selling_price: item.selling_price,
            stock: item.quantity,
            currency: item.currency,
            storage_terms: item.storage_terms,
            created_on: item.created_on,
            measurement: item.measurement,
          }));

          setProducts(data);
          setTotalPages(Math.ceil(response.returnData.total / ITEMS_PER_PAGE));

          // Extract unique values for filters
          const uniqueBusinesses: any = [
            ...new Set(data.map((item) => item.business)),
          ];
          const uniqueMeasurements: any = [
            ...new Set(data.map((item) => item.measurement)),
          ];
          setBusinesses(uniqueBusinesses);
          setMeasurements(uniqueMeasurements);
        } else {
          toast.error(response.returnMessage || "Failed to fetch inventory");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        toast.error("Failed to fetch products");
      } finally {
        setIsLoading(false);
      }
    },
    [filters]
  );

  const debouncedSearch = debounce((value: string) => {
    setCurrentPage(1);
    fetchInventory(1, value);
  }, 500);

  useEffect(() => {
    fetchInventory(currentPage, searchTerm);
  }, [currentPage, filters, fetchInventory]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (key: keyof FilterState, value: string | null) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setFilters({
      business: null,
      measurement: null,
      minPrice: "",
      maxPrice: "",
      minStock: "",
      maxStock: "",
    });
    setCurrentPage(1);
  };

  const handleEdit = (product: Product) => {
    setSelectedProduct(product);
    setShowForm(true);
    setShowActionMenu(null);
  };

  const handleDelete = async (productId: string) => {
    if (!window.confirm("Are you sure you want to delete this product?")) {
      return;
    }

    setIsLoading(true);
    try {
      const response = await sendPostToServer(
        "SalesManagement",
        "deleteInventoryItem",
        JSON.stringify({
          auth: loggedInUser(),
          productId,
        })
      );

      if (response.returnCode === 200) {
        toast.success("Product deleted successfully");
        fetchInventory(currentPage, searchTerm);
      } else {
        toast.error(response.returnMessage || "Failed to delete product");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Failed to delete product");
    } finally {
      setIsLoading(false);
      setShowActionMenu(null);
    }
  };

  // Close action menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        showActionMenu &&
        !(event.target as Element).closest(".action-menu")
      ) {
        setShowActionMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [showActionMenu]);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
        <h1 className="text-2xl font-bold">Inventory Management</h1>
        <button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 bg-[--primary-color] text-white px-4 py-2 rounded-lg hover:bg-[--primary-color-hover]"
        >
          <Plus size={20} />
          Add Stock
        </button>
      </div>

      <div className="mb-6 space-y-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Search products..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={handleSearch}
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
          <button
            onClick={() => setShowFilters(!showFilters)}
            className={cn(
              "flex items-center gap-2 px-4 py-2 border rounded-lg",
              showFilters && "bg-gray-100"
            )}
          >
            <Filter size={20} />
            Filters
          </button>
        </div>

        {showFilters && (
          <div className="bg-white p-4 rounded-lg border space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">
                  Business
                </label>
                <Select
                  isClearable
                  options={businesses.map((b) => ({ value: b, label: b }))}
                  value={
                    filters.business
                      ? { value: filters.business, label: filters.business }
                      : null
                  }
                  onChange={(option) =>
                    handleFilterChange("business", option?.value || null)
                  }
                  className="w-full"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Measurement
                </label>
                <Select
                  isClearable
                  options={measurements.map((m) => ({ value: m, label: m }))}
                  value={
                    filters.measurement
                      ? {
                          value: filters.measurement,
                          label: filters.measurement,
                        }
                      : null
                  }
                  onChange={(option) =>
                    handleFilterChange("measurement", option?.value || null)
                  }
                  className="w-full"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Price Range
                </label>
                <div className="flex gap-2">
                  <input
                    type="number"
                    placeholder="Min"
                    className="w-full p-2 border rounded-lg"
                    value={filters.minPrice}
                    onChange={(e) =>
                      handleFilterChange("minPrice", e.target.value)
                    }
                  />
                  <input
                    type="number"
                    placeholder="Max"
                    className="w-full p-2 border rounded-lg"
                    value={filters.maxPrice}
                    onChange={(e) =>
                      handleFilterChange("maxPrice", e.target.value)
                    }
                  />
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium mb-1">
                  Stock Range
                </label>
                <div className="flex gap-2">
                  <input
                    type="number"
                    placeholder="Min"
                    className="w-full p-2 border rounded-lg"
                    value={filters.minStock}
                    onChange={(e) =>
                      handleFilterChange("minStock", e.target.value)
                    }
                  />
                  <input
                    type="number"
                    placeholder="Max"
                    className="w-full p-2 border rounded-lg"
                    value={filters.maxStock}
                    onChange={(e) =>
                      handleFilterChange("maxStock", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={resetFilters}
                className="text-gray-600 hover:text-gray-900"
              >
                Reset Filters
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="overflow-x-auto relative">
          <table className="w-full">
            <thead className="">
              <tr className="bg-[--secondary_color] border-b">
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  #
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Product
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Purchase Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Selling Price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Currency
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Measurement
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Barcode
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Business
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">
                  Unit
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider">
                  Stock
                </th>
                <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider sticky right-0 bg-[--primary-color]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {isLoading ? (
                <tr>
                  <td colSpan={11} className="px-6 py-4 text-center">
                    <Spinner className="mx-auto" />
                  </td>
                </tr>
              ) : products.length === 0 ? (
                <tr>
                  <td
                    colSpan={11}
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    No products found
                  </td>
                </tr>
              ) : (
                products.map((product: any, index) => (
                  <tr key={product.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        {product.images && (
                          <img
                            src={product.images}
                            alt={product.name}
                            className="w-10 h-10 rounded object-cover mr-3"
                          />
                        )}
                        <span className="font-medium">{product.name}</span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatCurrency(product.purchase_price, product.currency)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {formatCurrency(product.selling_price, product.currency)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.currency}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.measurement}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.barcode}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.business}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {product.unit}
                      {product.measurement !== "count" &&
                        product.measurement &&
                        ` (${product.measurement})`}
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap">
                      {product.stock}
                    </td>
                    <td className="px-6 py-4 text-right whitespace-nowrap sticky right-0 bg-white">
                      <div className="relative action-menu">
                        <button
                          onClick={() =>
                            setShowActionMenu(
                              showActionMenu === product.id ? null : product.id
                            )
                          }
                          className="text-gray-400 hover:text-gray-600"
                        >
                          <MoreHorizontal size={20} />
                        </button>
                        {showActionMenu === product.id && (
                          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
                            <div className="py-1">
                              <button
                                onClick={() => handleEdit(product)}
                                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              >
                                Edit
                              </button>
                              <button
                                onClick={() => handleDelete(product.id)}
                                className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        {!isLoading && products.length > 0 && (
          <div className="px-6 py-4 flex items-center justify-between border-t">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * ITEMS_PER_PAGE + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(currentPage * ITEMS_PER_PAGE, products.length)}
                  </span>{" "}
                  of <span className="font-medium">{products.length}</span>{" "}
                  results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    <span className="sr-only">Previous</span>
                    <ChevronLeft className="h-5 w-5" />
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (page) => (
                      <button
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className={cn(
                          "relative inline-flex items-center px-4 py-2 border text-sm font-medium",
                          page === currentPage
                            ? "z-10 bg-[--primary-color] border-[--primary-color] text-white"
                            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                        )}
                      >
                        {page}
                      </button>
                    )
                  )}
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:bg-gray-100 disabled:text-gray-400"
                  >
                    <span className="sr-only">Next</span>
                    <ChevronRight className="h-5 w-5" />
                  </button>
                </nav>
              </div>
            </div>
          </div>
        )}
      </div>

      {showForm && (
        <InventoryForm
          onClose={() => {
            setShowForm(false);
            setSelectedProduct(null);
            fetchInventory(currentPage, searchTerm);
          }}
          product={selectedProduct}
        />
      )}
    </div>
  );
}

export default Inventory;
