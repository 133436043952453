import { format } from "date-fns";
import React from "react";
import { Sale } from "../../types/inventory";

interface ReceiptProps {
  sale: Sale;
  componentRef?: React.RefObject<HTMLDivElement>;
}

function Receipt({ sale, componentRef }: ReceiptProps) {
  return (
    <div ref={componentRef} className="bg-white p-6 max-w-md mx-auto">
      <div className="text-center mb-6">
        <h2 className="text-xl font-bold">Supermarket POS</h2>
        <p className="text-gray-600 text-sm">
          {format(sale.timestamp, "MMM d, yyyy h:mm a")}
        </p>
        <p className="text-gray-600 text-sm">Receipt #{sale.id.slice(0, 8)}</p>
      </div>

      <div className="border-t border-b py-4 my-4">
        <table className="w-full">
          <thead>
            <tr className="text-left text-sm">
              <th>Item</th>
              <th className="text-right">Qty</th>
              <th className="text-right">Price</th>
              <th className="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            {sale.items.map((item) => (
              <tr key={item.id} className="text-sm">
                <td className="py-1">{item.name}</td>
                <td className="text-right">{item.quantity}</td>
                <td className="text-right">${item.price.toFixed(2)}</td>
                <td className="text-right">
                  ${(item.price * item.quantity).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="space-y-2">
        <div className="flex justify-between">
          <span>Subtotal</span>
          <span>${sale.total.toFixed(2)}</span>
        </div>
        <div className="flex justify-between font-bold text-lg">
          <span>Total</span>
          <span>${sale.total.toFixed(2)}</span>
        </div>
        <div className="text-gray-600">
          <div className="flex justify-between text-sm">
            <span>Payment Method</span>
            <span className="capitalize">{sale.paymentMethod}</span>
          </div>
          {sale.paymentMethod === "cash" && (
            <>
              <div className="flex justify-between text-sm">
                <span>Cash Received</span>
                <span>${sale.cashReceived?.toFixed(2)}</span>
              </div>
              <div className="flex justify-between text-sm">
                <span>Change</span>
                <span>${sale.change?.toFixed(2)}</span>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="text-center mt-6 text-gray-600 text-sm">
        <p>Thank you for shopping with us!</p>
        <p>Please keep this receipt for returns</p>
      </div>
    </div>
  );
}

export default Receipt;
