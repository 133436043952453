import url from "../env.json";

// Global server variables
let SERVER_URL = "";
let UPLOAD_URL = "";

// Set server URLs based on environment
function setServerUrls(): void {
  const { host } = window.location;

  if (host === "localhost:3006") {
    SERVER_URL = url.PANGISA_LOCAL_URL;
    UPLOAD_URL = url.PANGISA_LOCAL_URL;
  } else if (host === "uat.nepserv.co.ug") {
    SERVER_URL = url.PANGISA_UAT_URL_INT;
    UPLOAD_URL = url.PANGISA_UAT_URL_INT;
  } else if (host === "uat.nepserv.co.ug:4443") {
    SERVER_URL = url.PANGISA_UAT_URL_EXT;
    UPLOAD_URL = url.PANGISA_UAT_URL_EXT;
  } else {
    SERVER_URL = url.PANGISA_PROD_URL;
    UPLOAD_URL = url.PANGISA_PROD_URL;
  }
}

// Call this immediately to set the URLs
setServerUrls();

// Function to handle JSON extraction safely
const extractData = async (res: Response): Promise<any> => {
  const text = await res.text();
  return text ? JSON.parse(text) : {};
};

const sendPostToServer = async (
  serviceHelper: string,
  endpoint: string,
  data: any
): Promise<any> => {
  const uri = `${SERVER_URL}${serviceHelper}/${endpoint}`;
  console.log("Request URL:", uri);

  const httpHeaders: RequestInit = {
    mode: "cors",
    method: "POST",
    body: data,
  };

  try {
    const response = await fetch(uri, httpHeaders);
    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const jsonResponse = await response.json();
    if (jsonResponse.returnCode === 7777) {
      logDevMode(
        `Unauthorized access to ${uri} - ${JSON.stringify(jsonResponse)}`
      );
      return {
        returnCode: 403,
        returnMessage: "Please login to complete request",
      };
    }

    return jsonResponse;
  } catch (error) {
    console.error("API Fetch Error:", error);
    return { returnCode: 500, returnMessage: error.message };
  }
};

const sendGetToServer = async (
  uri: string,
  params?: Record<string, any>
): Promise<any> => {
  const queryString = params
    ? `?${new URLSearchParams(params).toString()}`
    : "";

  try {
    const response = await fetch(`${SERVER_URL}${uri}${queryString}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include" as RequestCredentials,
    });

    if (!response.ok) {
      const errorData = await extractData(response);
      throw new Error(
        `HTTP error! Status: ${response.status}, Message: ${errorData.returnMessage}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("API GET Error:", error);
    throw error;
  }
};

const sendPostWithUpload = async (
  uri: string,
  formData: FormData
): Promise<any> => {
  try {
    const response = await fetch(`${UPLOAD_URL}${uri}`, {
      method: "POST",
      body: formData,
      credentials: "include" as RequestCredentials,
    });

    if (!response.ok) {
      const errorData = await extractData(response);
      throw new Error(
        `Upload error! Status: ${response.status}, Message: ${errorData.returnMessage}`
      );
    }

    return await response.json();
  } catch (error) {
    console.error("Upload Error:", error);
    throw error;
  }
};

const getPentahoReport = async (
  reportName: string,
  reportType: string,
  params: Record<string, any>
): Promise<Blob> => {
  const pentahoServerUrl =
    process.env.NODE_ENV !== "production"
      ? url.PENTAHO_UAT_URL
      : url.PENTAHO_PROD_URL;
  const queryString = params
    ? `?${new URLSearchParams(params).toString()}`
    : "";

  try {
    const response = await fetch(
      `${pentahoServerUrl}${reportName}/${reportType}${queryString}`,
      {
        method: "GET",
        credentials: "include" as RequestCredentials,
      }
    );

    if (!response.ok)
      throw new Error(`Pentaho report error! Status: ${response.status}`);
    return await response.blob();
  } catch (error) {
    console.error("Pentaho Report Error:", error);
    throw error;
  }
};

// Logging utilities
const logDevMode = (...data: any[]): void => {
  if (process.env.NODE_ENV !== "production") console.log(...data);
};

const logAlert = (message: any): void => {
  if (process.env.NODE_ENV !== "production")
    alert(
      typeof message === "object" ? JSON.stringify(message, null, 2) : message
    );
};

// Export the functions
export {
  getPentahoReport,
  sendGetToServer,
  sendPostToServer,
  sendPostWithUpload,
};
