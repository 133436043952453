import { ErrorMessage, Field, Form, Formik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import ButtonBase from "../../components/CustomComponents/Buttons/ButtonBase.tsx";
import ImageUpload from "../../components/CustomComponents/ImageUpload";
import CustomBreadCrumbs from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import { getCookie, userDashboard } from "../../service/get-user";
import { CommonComponent, formatAndParseNumber } from "../../service/index";

// Define the form values interface
interface PackageFormValues {
  packageName: string;
  packagePrice: string; // Changed to string
  expireDate: string; // Using string for simplicity in date handling
  packageType: number;
  beforeEndDate: string; // Same as above
  packageStartDate: string; // Same as above
  id: number;
}

// Validation schema using Yup
const validationSchema = Yup.object({
  packageName: Yup.string().required("Package Name is required"),
  packagePrice: Yup.string()
    .required("Price must be a positive number")
    .required("Package Price is required"),
  expireDate: Yup.date().required("Expire Date is required"),
  beforeEndDate: Yup.date().required("Before End Date is required"),
  packageStartDate: Yup.date().required("Package Start Date is required"),
  packageType: Yup.string().required("Package Type is required"),
});

// Utility function to format date as YYYY-MM-DD
const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toISOString().split("T")[0]; // Format as YYYY-MM-DD
};

const EditPackage = () => {
  const { PackageId } = useParams();
  const [initialValues, setInitialValues] = useState<PackageFormValues>({
    packageName: "",
    packagePrice: "", // Changed to empty string
    expireDate: "",
    packageType: 0,
    beforeEndDate: "",
    packageStartDate: "",
    id: 0,
  });
  const [, setloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const auth = getCookie("loggedInUser");

  const [packageTypeOptions] = useState([
    { label: "default", value: "default" },
    { label: "Schoolpay", value: "Schoolpay" },
  ]);

  const commonComponent = new CommonComponent();
  const navigation = useNavigate();
  const removeCommas = (value: string): number => {
    return Number(value.replace(/,/g, ""));
  };

  const handleSubmit = (values: PackageFormValues) => {
    const packagePrice = Number(removeCommas(values.packagePrice));
    const grandPackagePrice = packagePrice + Number(5000);

    setloading(true);

    const postObject = {
      auth: getCookie("loggedInUser"),
      requestData: {
        ...values,
        grandPackagePrice, // Add the calculated value
        packageImage: selectedImage,
        packagePrice,
      },
    };

    try {
      commonComponent
        .sendRequestToServer(
          "ProductManagement",
          "addSchoolpayPackages", // Update the package instead of adding
          JSON.stringify(postObject)
        )
        .then((data: any) => {
          if (data.returnCode === 200) {
            navigation("/Packages");
            setloading(false);
          } else {
            console.log("Error:", data.returnMessage);
            setloading(false);
          }
        })
        .catch((error: any) => {
          console.log("Error:", error);
          setloading(false);
        });
    } catch (error) {
      console.log("Error:", error);
      setloading(false);
    }
  };

  useEffect(() => {
    const getPackages = () => {
      const postObject = {
        auth,
        search: { id: PackageId },
      };
      commonComponent
        .sendRequestToServer(
          "ProductManagement",
          "getSchoolpayPackages",
          JSON.stringify(postObject)
        )
        .then((data) => {
          if (data.returnCode === 200) {
            if (data.returnData && data.returnData.rows) {
              let rows = data.returnData.rows[0];
              setInitialValues({
                packageName: rows.package_name || "",
                packagePrice: rows.package_price || "", // Changed to string
                expireDate: formatDate(rows.package_end_date) || "",
                beforeEndDate: formatDate(rows.package_end_date) || "",
                packageStartDate: formatDate(rows.package_start_date) || "",
                id: rows.id,
                packageType: rows.package_type || "",
              });

              // Set initial image if available
              setSelectedImage(rows.package_image || "");
            } else {
              console.error("Invalid data structure", data);
            }
          }
        })
        .catch((error) => {
          console.error(
            "Error occurred while retrieving packages:",
            error.message
          );
        });
    };
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard()) },
    { label: "Package Management", route: () => navigation("/package") },
    { label: "Package Customization", route: () => navigation("/details") }, // Route ignored since it's the last
  ];

  return (
    <div className="my-16">
      <div className="mx-3">
        <CustomBreadCrumbs breadcrumbs={breadcrumbs} />
      </div>
      <div className="w-full flex items-center justify-center overflow-hidden">
        <div className="w-[96%] bg-white rounded-3xl p-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ setFieldValue, values, errors, touched }) => (
              <Form className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full">
                <div className="flex flex-col">
                  <label htmlFor="packageName" className="mb-2 text-gray-700">
                    Package Name:
                  </label>
                  <Field
                    placeholder="Package Name"
                    name="packageName"
                    type="text"
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="packageName"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="packagePrice" className="mb-2 text-gray-700">
                    Package Price:
                  </label>
                  <Field
                    name="packagePrice"
                    type="text" // Changed to text
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let formattedValue = formatAndParseNumber(e.target.value);
                      setFieldValue("packagePrice", formattedValue);
                    }}
                  />
                  <ErrorMessage
                    name="packagePrice"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="packageStartDate"
                    className="mb-2 text-gray-700"
                  >
                    Package Start Date:
                  </label>
                  <Field
                    name="packageStartDate"
                    type="date"
                    min={values.packageStartDate}
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="packageStartDate"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label htmlFor="expireDate" className="mb-2 text-gray-700">
                    Expire Date:
                  </label>
                  <Field
                    name="expireDate"
                    type="date"
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="expireDate"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="beforeEndDate" className="mb-2 text-gray-700">
                    Before End Date:
                  </label>
                  <Field
                    name="beforeEndDate"
                    type="date"
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="beforeEndDate"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                <div className="flex flex-col">
                  <label
                    htmlFor="grandPackagePrice"
                    className="mb-2 text-gray-700"
                  >
                    Commission:
                  </label>
                  <Dropdown
                    id="commission"
                    value={values.packageType}
                    options={packageTypeOptions}
                    onChange={(e) => setFieldValue("packageType", e.value)} // Ensure it's a number
                    placeholder="Select Package Type"
                    className={`p-dropdown text-white${
                      touched.packageType && errors.packageType && "p-invalid"
                    }`}
                  />

                  <ErrorMessage
                    name="packageType"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                <ImageUpload
                  label="Upload Package Image"
                  onChange={(image: any) => setSelectedImage(image)}
                  initialImage={selectedImage}
                />

                <div className="md:col-span-2 flex justify-end">
                  <div>
                    <ButtonBase
                      title="Finish"
                      className={"w-24 text-white"}
                      theme={"secondary"}
                      mode={"filled"}
                      type="submit" // This triggers Formik's form submission
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditPackage;
