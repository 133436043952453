import { openDB } from "idb";
import { Product, Sale } from "../types/inventory";

const DB_NAME = "supermarket_pos";
const DB_VERSION = 1;

export const initDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      if (!db.objectStoreNames.contains("products")) {
        db.createObjectStore("products", { keyPath: "id" });
      }
      if (!db.objectStoreNames.contains("sales")) {
        db.createObjectStore("sales", { keyPath: "id" });
      }
    },
  });
  return db;
};

export const addProduct = async (product: Product) => {
  const db = await initDB();
  await db.put("products", product);
};

export const getProducts = async () => {
  const db = await initDB();
  return db.getAll("products");
};

export const addSale = async (sale: Sale) => {
  const db = await initDB();
  await db.put("sales", sale);
};

export const getSales = async () => {
  const db = await initDB();
  return db.getAll("sales");
};
