import { Button, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { Package, User } from "lucide-react";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import defaultprofile from "../../assets/img/profilePic.jpg";
import { AppContext } from "../../context/AppContext";
import { routings } from "../../routes/routings.ts";
import { CommonComponent } from "../../service/CommonComponent";
import { getCookie, userDashboard } from "../../service/get-user";
import CustomBreadCrumb from "../CustomComponents/Tables/CustomBreadCrumb.tsx";

const UserDetails = () => {
  const { customerId } = useParams();
  const { setSnackAlert, setLoading } = useContext(AppContext);

  const [rowdata, setRowData] = useState();
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false);
  const [actionType, setActionType] = React.useState("");
  const [userDetails, setuserDetails] = useState({
    firstName: "",
    lastName: "",
    middleName: "",
    emailAddress: "",
    primaryTel: "",
    profilePic: "",
    status: "",
    lastSeen: "",
    userType: "",
    createdOn: "",
  });

  const handleAction = (type) => {
    setActionType(type);
    setIsOverlayVisible(true);
  };

  const confirmAction = () => {
    if (actionType === "approve") {
      handleAproval("Approved");
    } else {
      handleAproval("Rejected");
    }
    setIsOverlayVisible(false);
  };

  const commonComponent = new CommonComponent();
  const navigation = useNavigate();

  useEffect(() => {
    getCustomerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCustomerInfo = () => {
    setLoading(true);
    let postObject = {
      userId: customerId,
    };
    commonComponent
      .sendRequestToServer(
        "CustomerManagement",
        "getCustomerDetails",
        JSON.stringify(postObject)
      )
      .then((data) => {
        if (data.returnCode === 200) {
          setuserDetails({
            firstName: data.returnData.firstName || "",
            lastName: data.returnData.lastName || "",
            middleName: data.returnData.middleName || "",
            emailAddress: data.returnData.emailAddress || "",
            primaryTel: data.returnData.phoneNo || "",
            profilePic: data.returnData.profilePicture || "",
            status: data.returnData.status || "",
            lastSeen:
              new Date(data.returnData.lastSeen).toISOString().split("T")[0] ||
              "",
            userType:
              data.returnData.userRole === 5 || data.returnData.userRole === 4
                ? "Pangisa Admin"
                : data.returnData.userRole === 6
                ? "Vendor"
                : "Field Officer/Delivery Agent" || "",
            createdOn: new Date(data.returnData.createdOn)
              .toISOString()
              .split("T")[0],
          });
          setRowData(data.returnData); // Update state
          setLoading(false);
        } else {
          console.log("Oops.. we are having trouble fetching your profile");
        }
      });
  };

  const handleAproval = (status) => {
    setLoading(true);
    let rows = [rowdata];
    const statusReturnMessage =
      status === "Rejected"
        ? `The package ${
            userDetails.firstName + " " + userDetails.lastName
          } has been Rejected successfully.`
        : `The package ${
            userDetails.firstName + " " + userDetails.lastName
          } has been approved successfully.`;
    let postObject = {
      auth: getCookie("loggedInUser"),
      search: {},
      requestData: { users: rows, status: status },
    };

    commonComponent
      .sendRequestToServer(
        "CustomerManagement",
        "approveCustomer",
        JSON.stringify(postObject)
      )
      .then((data) => {
        if (data.returnCode === 200) {
          setLoading(false);
          setSnackAlert({
            open: true,
            severity: "success",
            message: statusReturnMessage,
          });
          setTimeout(() => {
            navigation(routings.pending_approvals);
          }, 3000);

          // handleReset();
        } else {
          setLoading(false);
          setSnackAlert({
            show: true,
            severity: "error",
            message: "Ooups..we are having some difficulties saving changes",
          });
        }
      });
  };
  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard()) },
    {
      label: "Users' Approvals",
      route: () => navigation(routings.pending_approvals),
    },
    { label: "User's information", route: () => navigation("/details") }, // Route ignored since it's the last
  ];

  return (
    <section className="profile-section mt-16">
      <CustomBreadCrumb breadcrumbs={breadcrumbs} />

      <div className="container">
        <div className="card shadow-lg rounded-lg bg-white flex flex-col md:flex-row">
          {/* Left Section: Profile Picture and User Details */}
          <Card
            elevation={0}
            sx={{
              overflow: "initial",
              // maxWidth: "80%", // Full width
              // width: "80%", // Ensure it spans the container
              backgroundColor: "transparent",
            }}
          >
            <CardMedia
              image={userDetails.image || defaultprofile}
              sx={{
                width: "100%",
                height: 0,
                paddingBottom: "56.25%",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
              }}
              alt="profile loading from server"
            />
            <CardContent
              sx={{
                p: 3,
                backgroundColor: "rgba(245, 245, 245, 0.8)",
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "secondary",
                  mb: 2,
                  fontSize: "1.2rem",
                }}
                variant="h6"
              >
                <div className="flex justify-between items-center">
                  <div className="flex font-extrabold">
                    {userDetails.firstName + " " + userDetails.lastName}
                  </div>

                  <User />
                </div>
              </Typography>
              <Divider sx={{ mb: 1, color: "orange", fontWeight: "bold" }} />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 1,
                }}
              >
                {[
                  { label: "First Name", value: userDetails.firstName },
                  { label: "Last Name", value: userDetails.lastName },
                  {
                    label: "Phone Number",
                    value: userDetails.primaryTel?.replace(/\s+/g, "").trim(),
                  },
                  { label: "Email Address", value: userDetails.emailAddress },
                ].map((detail, index) => (
                  <Box key={index}>
                    <Typography
                      sx={{ color: "text.secondary", fontSize: "0.8rem" }}
                      variant="caption"
                    >
                      {detail.label}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "medium",
                        color: detail.value ? "text.primary" : "text.disabled",
                        display: "inline",
                        whiteSpace: "nowrap",
                        justifyContent: "space-between",
                      }}
                      variant="body1"
                    >
                      {" "}
                      {detail.value || "N/A"}
                    </Typography>
                  </Box>
                ))}
              </Box>

              <Button
                color="warning"
                variant="outlined"
                fullWidth
                sx={{
                  mt: 3,
                  py: 1.5,
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  borderRadius: 2,
                  boxShadow: 3,
                  // "&:hover": {
                  //   backgroundColor: "orange",
                  // },
                }}
              >
                Shopping On pangisa <Package sx={{ ml: 1 }} />
              </Button>
            </CardContent>
          </Card>

          {/* Right Section: Account Details and Actions */}
          <Card
            elevation={0}
            sx={{
              padding: "16px",
              borderRadius: "12px",
              // background: "linear-gradient(to bottom, var(--primary-color-light), white)",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  color: "primary.main",
                  borderBottom: "1px solid #e0e0e0",
                  paddingBottom: "8px",
                  marginBottom: "16px",
                }}
              >
                {userDetails.userType || "User"} Approval
              </Typography>

              <Box sx={{ marginBottom: "24px" }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.875rem",
                    color: "text.secondary",
                    marginBottom: "12px",
                  }}
                >
                  Below are the account details for {userDetails.firstName}.
                  This account is categorized as a {userDetails.userType}.
                  Kindly review the information thoroughly before proceeding
                  with any actions.
                </Typography>

                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "16px",
                    backgroundColor: "rgba(240, 240, 240, 0.5)",
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  {[
                    { label: "Account/User Type", value: userDetails.userType },
                    { label: "Last Seen Active", value: userDetails.lastSeen },
                    {
                      label: "Account Creation Date",
                      value: userDetails.createdOn,
                    },
                    {
                      label: "Account Current Status",
                      value: (
                        <Typography
                          sx={{
                            fontSize: "0.875rem",
                            fontWeight: "bold",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            display: "inline-block",
                            backgroundColor:
                              userDetails.status === "Approved"
                                ? "rgba(76, 175, 80, 0.1)"
                                : userDetails.status === "Awaiting Approval"
                                ? "rgba(255, 193, 7, 0.1)"
                                : "rgba(244, 67, 54, 0.1)",
                            color:
                              userDetails.status === "Approved"
                                ? "green"
                                : userDetails.status === "Awaiting Approval"
                                ? "orange"
                                : "red",
                          }}
                        >
                          {userDetails.status || "N/A"}
                        </Typography>
                      ),
                    },
                  ].map((detail, index) => (
                    <Box key={index}>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "0.75rem", color: "text.secondary" }}
                      >
                        {detail.label}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ fontSize: "0.875rem", fontWeight: "medium" }}
                      >
                        {detail.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "0.875rem",
                    color: "text.secondary",
                    marginBottom: "16px",
                  }}
                >
                  Please select an appropriate action below to either approve or
                  reject the user account. Ensure all details are verified.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "16px",
                  }}
                >
                  {/* Show the Reject button only if the status is not "Reject" */}
                  {userDetails.status !== "Rejected" && (
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        padding: "8px 16px",
                      }}
                      onClick={() => handleAction("decline")}
                    >
                      Reject
                    </Button>
                  )}

                  {/* Show the Approve/Reject button based on specific statuses */}
                  {(userDetails.status === "Awaiting Approval" ||
                    userDetails.status === "Rejected") && (
                    <Button
                      variant="contained"
                      color={
                        userDetails.status === "Approved" ? "error" : "warning"
                      } // "warning" used for orange
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "0.875rem",
                        padding: "8px 16px",
                      }}
                      onClick={() =>
                        handleAction(
                          userDetails.status === "Approved"
                            ? "Reject"
                            : "approve"
                        )
                      }
                    >
                      {userDetails.status === "Approved" ? "Reject" : "Approve"}
                    </Button>
                  )}
                </Box>

                <div className="bg-gradient-to-t from-gray-300 to-white h-20 -mb-20 w-[105%]"></div>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Overlay Confirmation Modal */}
      {isOverlayVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-bold text-gray-800 mb-4">
              {actionType === "approve"
                ? "Confirm Approval"
                : "Confirm Decline"}
            </h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to {actionType} this user?
            </p>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300 transition"
                onClick={() => setIsOverlayVisible(false)}
              >
                Cancel
              </button>
              <button
                className={`px-4 py-2 rounded-lg text-white ${
                  actionType === "approve"
                    ? "bg-[--primary-color] hover:bg-[--secondary_color]"
                    : "bg-red-500 hover:bg-red-600"
                }`}
                onClick={() => confirmAction()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default UserDetails;
