import { COMPLETE_SCHOOL_ENROLLMENT, LOGGED_IN } from "../types/types";

type ActionTypes = {
  type: string;
  payload: any;
};

const initialState = {
  isAuthenticated: false,
  items: [],
};

export const productsReducer = (
  state = initialState,
  { type, payload }: ActionTypes
) => {
  switch (type) {
    case COMPLETE_SCHOOL_ENROLLMENT:
      return {
        ...state,
        completion_rate: payload,
      };
    case LOGGED_IN:
      console.log("Action: " + type, "Payload: " + JSON.stringify(payload));

      return {
        ...state,
        isAuthenticated: true,
        user: payload,
      };
    case "LOGOUT":
      return initialState;
    default:
      return state;
  }
};
