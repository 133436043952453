import { Minus, Plus, Search, ShoppingCart, Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentModal from "../../components/Sales/PaymentModal.tsx";
import { RootState } from "../../redux/helpers/store.ts";
import {
  addToCart,
  clearCart,
  updateQuantity,
} from "../../redux/slice/cartSlice.ts";
import { Product } from "../../types/inventory.ts";

function POS() {
  const dispatch = useDispatch();
  const { items, total } = useSelector((state: RootState) => state.cart);
  const [showPayment, setShowPayment] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const products = useSelector((state: RootState) => state.products.items);
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.barcode.includes(searchTerm)
  );

  return (
    <div className="flex gap-6">
      {/* Products Section */}
      <div className="flex-1">
        <div className="mb-6">
          <div className="relative">
            <input
              type="text"
              placeholder="Search products or scan barcode..."
              className="w-full pl-10 pr-4 py-2 border rounded-lg"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search
              className="absolute left-3 top-2.5 text-gray-400"
              size={20}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredProducts.map((product: Product) => (
            <button
              key={product.id}
              onClick={() => dispatch(addToCart(product))}
              className="bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow"
            >
              {product.image && (
                <img
                  src={product.image}
                  alt={product.name}
                  className="w-full h-32 object-cover rounded-md mb-3"
                />
              )}
              <h3 className="font-medium text-gray-800">{product.name}</h3>
              <p className="text-blue-600 font-bold">
                ${product.price.toFixed(2)}
              </p>
              <p className="text-sm text-gray-500">Stock: {product.stock}</p>
            </button>
          ))}
        </div>
      </div>

      {/* Cart Section */}
      <div className="w-96 bg-white rounded-lg shadow-lg p-6 h-[calc(100vh-8rem)] flex flex-col">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-bold flex items-center gap-2">
            <ShoppingCart size={24} />
            Cart
          </h2>
          {items.length > 0 && (
            <button
              onClick={() => dispatch(clearCart())}
              className="text-red-500 hover:text-red-700"
            >
              <Trash2 size={20} />
            </button>
          )}
        </div>

        <div className="flex-1 overflow-auto">
          {items.length === 0 ? (
            <div className="text-center text-gray-500 mt-8">Cart is empty</div>
          ) : (
            <div className="space-y-4">
              {items.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
                >
                  <div className="flex-1">
                    <h3 className="font-medium">{item.name}</h3>
                    <p className="text-sm text-gray-500">
                      ${item.price.toFixed(2)} each
                    </p>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() =>
                        dispatch(
                          updateQuantity({
                            id: item.id,
                            quantity: Math.max(0, item.quantity - 1),
                          })
                        )
                      }
                      className="p-1 rounded-full hover:bg-gray-200"
                    >
                      <Minus size={16} />
                    </button>
                    <span className="w-8 text-center">{item.quantity}</span>
                    <button
                      onClick={() =>
                        dispatch(
                          updateQuantity({
                            id: item.id,
                            quantity: item.quantity + 1,
                          })
                        )
                      }
                      className="p-1 rounded-full hover:bg-gray-200"
                    >
                      <Plus size={16} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="border-t pt-4 mt-4">
          <div className="flex justify-between text-lg font-bold mb-4">
            <span>Total</span>
            <span>${total.toFixed(2)}</span>
          </div>
          <button
            onClick={() => setShowPayment(true)}
            disabled={items.length === 0}
            className="w-full bg-blue-500 text-white py-3 rounded-lg font-medium
              disabled:bg-gray-300 disabled:cursor-not-allowed
              hover:bg-blue-600 transition-colors"
          >
            Proceed to Payment
          </button>
        </div>
      </div>

      {showPayment && (
        <PaymentModal
          onClose={() => setShowPayment(false)}
          total={total}
          items={items}
        />
      )}
    </div>
  );
}

export default POS;
