import { Field, FieldArray, Form, Formik } from "formik";
import {
  Boxes,
  DollarSign,
  Map,
  Package2,
  Plus,
  ShoppingCart,
  Trash2,
} from "lucide-react";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SnackBarAlert from "../../components/CustomComponents/Alerts/SnackBarAlert.tsx";
import ButtonBase from "../../components/CustomComponents/Buttons/ButtonBase.tsx";
import CustomBreadCrumb from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import { AppContext } from "../../context/AppContext.js";
import { routings } from "../../routes/routings.ts";
import { CommonComponent, convertCurrency } from "../../service";
import { getCookie, userDashboard } from "../../service/get-user";

const auth = getCookie("loggedInUser");

const PackageMapping = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<any>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [existId, setexistId] = useState<number>();
  const [msg, setMsg] = React.useState<any>({
    show: false,
    severity: error() || "success",
    message: "",
  });
  const [commisssionPrice, setCommisssionPrice] = useState<any>({
    totalPrice: "",
    totalwithCommission: "",
  });
  const { setLoading }: any = useContext(AppContext);

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard()) },
    { label: "Packages", route: () => navigation(routings.packages) },
    { label: "Package Mapping", route: () => navigation("/details") },
  ];

  const { packageId } = useParams();
  const request = new CommonComponent();
  const navigation = useNavigate();

  useEffect(() => {
    getProducts();
    getPackages();
    getPackagewithCommissionInfo();
  }, []);

  const getPackagewithCommissionInfo = () => {
    let postObject = {
      auth,
      requestData: {
        packageId: packageId,
      },
      grouped: { NoSort: "NoSort" },
    };

    request
      .sendRequestToServer(
        "ProductManagement",
        "getPackageCommissionDetail",
        JSON.stringify(postObject)
      )
      .then((data) => {
        const { returnCode, returnData } = data;
        if (returnCode === 200 && returnData.length > 0) {
          setCommisssionPrice({
            totalPrice: returnData[0].totalcost,
            totalwithCommission: returnData[0].grandprice,
          });
        }
      })
      .catch((error) => {
        console.log(
          "Oops.. we are having trouble fetching your profile",
          error
        );
      });
  };

  const getProducts = async () => {
    const postObject = {
      auth,
      search: {
        cliendId: getCookie("loggedInUser").user.clientId,
      },
    };

    try {
      const response = await request.sendRequestToServer(
        "ProductManagement",
        "getProducts",
        JSON.stringify(postObject)
      );

      if (response.returnCode === 200) {
        const productsList = response.returnData.rows;
        const listData = productsList.map((item: any) => ({
          productId: item.id,
          product_name: item.product_name,
          price_per_unit: item.price_per_unit,
          quantity: item.qty_available,
          description: item.description,
        }));

        setProducts(listData);
      } else {
        console.error("Unexpected return code", response.returnCode);
      }
    } catch (error) {
      console.error(
        "Error occurred!",
        "Oops..we are having some difficulties retrieving products."
      );
    }
  };

  const getPackages = async () => {
    const postObject = {
      auth,
      search: {},
      requestData: { packageId: packageId },
    };

    try {
      const data = await request.sendRequestToServer(
        "ProductManagement",
        "getAllSchoolPayPackageWithoutSession",
        JSON.stringify(postObject)
      );

      const { returnCode, returnData } = data;

      if (returnCode === 200) {
        if (returnData && returnData.rows && returnData.rows.length > 0) {
          const pack = returnData.rows[0];
          setSelectedPackage(pack);
        } else {
          console.error("Invalid data structure or no packages found:", data);
        }
      } else {
        console.error(
          "Unexpected return code",
          data.returnCode,
          data.returnMessage
        );
      }
    } catch (error) {
      console.error("Error occurred while retrieving packages:", error.message);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const products = values.rows.map((item) => ({
      productId: item.productlist ? item.productlist.productId : null,
      quantity: item.quantity,
    }));
    const postObject = {
      requestData: {
        packageId: selectedPackage.id,
        Product: products,
      },
    };

    const validProducts = postObject.requestData.Product.filter(
      (product) => product.productId !== null
    );

    if (validProducts.length > 0) {
      try {
        const data = await request.sendRequestToServer(
          "ProductManagement",
          "mapProductsToSpecifiedPackage",
          JSON.stringify(postObject)
        );

        const { returnCode, returnMessage, returnData } = data;

        if (returnCode === 200) {
          setMsg({
            show: true,
            severity: "success",
            message: returnMessage,
          });
          setLoading(false);
          navigation("/package-detail-school/" + selectedPackage.id);
        } else if (returnCode === 0) {
          setexistId(returnData);
          setLoading(false);
          setMsg({
            show: true,
            severity: "error",
            message: returnMessage,
          });
        } else {
          setLoading(false);
          console.error(
            "Unexpected return code",
            returnCode,
            "message",
            returnMessage
          );
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error.message);
      }
    } else {
      setLoading(false);
      setMsg({
        show: true,
        severity: "error",
        message: "Products not available, please select a valid product",
      });
    }
  };

  const calculateTotalCost = (rows) => {
    return rows.reduce((total, row) => total + (row.totalCost || 0), 0);
  };

  const handleAddProduct = (push: any, values: any) => {
    const lastRowIndex = values.rows.length - 1;

    if (lastRowIndex >= 0) {
      const lastRowProduct = values.rows[lastRowIndex].productlist;

      if (!lastRowProduct) {
        setMsg({
          show: true,
          severity: "error",
          message: "Select Product Name from the Last Row",
        });
        return;
      }
    }

    push({
      id: Date.now(),
      productlist: null,
      quantity: 1,
      totalCost: 0,
    });
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="max-w-[1400px] mx-auto px-4 py-8">
        <div className="mb-6">
          <CustomBreadCrumb breadcrumbs={breadcrumbs} />
        </div>

        <div className="space-y-8">
          <div className="flex flex-col md:flex-row justify-between items-start gap-6">
            <div className="flex items-center gap-4 bg-white p-4 rounded-xl shadow-sm">
              <div className="bg-orange-100 p-3 rounded-lg">
                <Package2 className="w-8 h-8 text-[--primary-color]" />
              </div>
              <div>
                <p className="text-sm text-gray-500 mb-1">Current Package</p>
                <h1 className="text-2xl font-bold text-gray-800">
                  {selectedPackage?.package_name || "Package Mapping"}
                </h1>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 w-full md:w-auto">
              {selectedPackage?.package_price && (
                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="bg-green-50 p-2 rounded-lg">
                      <DollarSign className="w-5 h-5 text-green-600" />
                    </div>
                    <p className="text-sm font-medium text-gray-600">
                      Package Price
                    </p>
                  </div>
                  <p className="text-xl font-bold text-gray-800">
                    {convertCurrency(selectedPackage?.package_price)}
                  </p>
                </div>
              )}

              {commisssionPrice.totalPrice && (
                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="bg-purple-50 p-2 rounded-lg">
                      <ShoppingCart className="w-5 h-5 text-purple-600" />
                    </div>
                    <p className="text-sm font-medium text-gray-600">
                      Total Products Price
                    </p>
                  </div>
                  <p className="text-xl font-bold text-gray-800">
                    {commisssionPrice.totalPrice}
                  </p>
                </div>
              )}

              {commisssionPrice.totalwithCommission && (
                <div className="bg-white rounded-xl shadow-sm p-4 border border-gray-100">
                  <div className="flex items-center gap-3 mb-3">
                    <div className="bg-orange-50 p-2 rounded-lg">
                      <Boxes className="w-5 h-5 text-orange-600" />
                    </div>
                    <p className="text-sm font-medium text-gray-600">
                      With Commission
                    </p>
                  </div>
                  <p className="text-xl font-bold text-gray-800">
                    {commisssionPrice.totalwithCommission}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="bg-white rounded-xl shadow-lg overflow-hidden">
            {selectedPackage ? (
              <Formik
                initialValues={{
                  rows: [
                    {
                      productlist: null,
                      quantity: 1,
                      totalQtyAvailable: products.map((i) => i.quantity),
                      totalCost: 0,
                    },
                  ],
                }}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => {
                  // eslint-disable-next-line react-hooks/rules-of-hooks
                  useEffect(() => {
                    setTotalCost(calculateTotalCost(values.rows));
                  }, [values.rows]);

                  return (
                    <Form className="divide-y divide-gray-100">
                      <div className="p-6">
                        <div className="flex gap-2 items-center">
                          <div className="bg-orange-100 p-2 rounded-lg flex justify-center items-center shadow-sm transition">
                            <Map className="w-8 h-8 text-orange-600" />
                          </div>
                          <h2 className="text-xl font-bold text-gray-900">
                            Product Mapping
                          </h2>
                        </div>

                        <FieldArray name="rows">
                          {({ push, remove }) => (
                            <>
                              <div className="grid grid-cols-6 gap-4 mb-4 py-3 bg-gray-50 rounded-lg px-4 text-sm font-medium text-gray-600">
                                <div>Item Name</div>
                                <div className="text-center">Quantity</div>
                                <div className="text-center">Available</div>
                                <div className="text-center">Cost/Unit</div>
                                <div className="text-center">Total</div>
                                <div className="text-center">Action</div>
                              </div>

                              <div className="space-y-3">
                                {values.rows.map((row: any, index) => (
                                  <div
                                    key={index}
                                    className="grid grid-cols-6 gap-4 items-center rounded-lg p-4 transition-colors hover:bg-gray-50"
                                  >
                                    <Dropdown
                                      value={row.productlist}
                                      options={products}
                                      onChange={(e) => {
                                        const productlist = e.value;
                                        const costPerUnit = productlist
                                          ? productlist.price_per_unit
                                          : 0;
                                        setFieldValue(
                                          `rows.${index}.productlist`,
                                          productlist
                                        );
                                        setFieldValue(
                                          `rows.${index}.totalCost`,
                                          costPerUnit * row.quantity
                                        );
                                      }}
                                      optionLabel="product_name"
                                      placeholder="Select product"
                                      className="w-full"
                                      filter
                                      filterBy="product_name"
                                    />

                                    <Field
                                      name={`rows.${index}.quantity`}
                                      type="number"
                                      min="1"
                                      className="w-full p-2 border border-gray-200 rounded-lg text-center bg-white focus:ring-2 focus:ring-blue-100 focus:border-blue-400 transition-all"
                                      onChange={(e) => {
                                        const quantity =
                                          parseInt(e.target.value) || 1;
                                        const costPerUnit = row.productlist
                                          ? row.productlist.price_per_unit
                                          : 0;
                                        setFieldValue(
                                          `rows.${index}.quantity`,
                                          quantity
                                        );
                                        setFieldValue(
                                          `rows.${index}.totalCost`,
                                          costPerUnit * quantity
                                        );
                                      }}
                                    />

                                    <div className="text-center font-medium text-gray-700">
                                      {row.productlist
                                        ? row.productlist.quantity
                                        : "—"}
                                    </div>

                                    <div className="text-center font-medium text-gray-700">
                                      {row.productlist
                                        ? new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "UGX",
                                          }).format(
                                            row.productlist.price_per_unit
                                          )
                                        : "—"}
                                    </div>

                                    <div className="text-center font-semibold text-[--primary-color]">
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "UGX",
                                      }).format(row.totalCost)}
                                    </div>

                                    <button
                                      type="button"
                                      onClick={() => remove(index)}
                                      className="mx-auto p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-lg transition-all duration-200"
                                    >
                                      <Trash2 className="w-5 h-5" />
                                    </button>
                                  </div>
                                ))}
                              </div>

                              <div className="px-6 py-4 bg-gray-50 flex flex-wrap items-center justify-between gap-4 mt-6">
                                <button
                                  type="button"
                                  onClick={() => handleAddProduct(push, values)}
                                  className="inline-flex items-center gap-2 px-5 py-2.5 border-2 border-[--primary-color] text-[--primary-color] bg-white rounded-lg transition-all duration-300 ease-in-out shadow-sm hover:shadow-md font-medium"
                                  aria-label="Add Product"
                                >
                                  <Plus className="w-5 h-5" />
                                  Add Product
                                </button>

                                <div className="flex items-center gap-4">
                                  <div className="text-sm text-gray-600">
                                    Total Cost:
                                    <span className="ml-2 font-semibold text-gray-900">
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "UGX",
                                      }).format(totalCost)}
                                    </span>
                                  </div>
                                  <ButtonBase
                                    type="submit"
                                    title="Map Products"
                                    mode="filled"
                                    theme="primary"
                                    className="px-6 py-2 bg-[--primary-color] text-white rounded-lg hover:bg-[--primary-color-hover] transition-colors"
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </FieldArray>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <div className="flex flex-col items-center justify-center py-16 text-gray-500">
                <div className="bg-gray-100 p-4 rounded-full mb-4">
                  <Package2 className="w-12 h-12 text-gray-400" />
                </div>
                <p className="text-lg">Select a package to add products</p>
                <p className="text-sm text-gray-400 mt-2">
                  No package selected
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <SnackBarAlert
        open={msg.show}
        onClose={() => setMsg({ show: false, message: "", severity: "" })}
        message={msg.message}
        severity={msg.severity}
      />
    </div>
  );

  function error(): string {
    return "error";
  }
};

export default PackageMapping;
