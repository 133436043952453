import * as React from "react";
import { useNavigate } from "react-router-dom";
import CustomDialog from "../../components/CustomComponents/Dialogs/CustomDialog.tsx";
import CustomBreadCrumb from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import CustomSelectableTable from "../../components/CustomComponents/Tables/CustomSelectableTable.tsx";
import { CommonComponent } from "../../service";
import { getCookie, userDashboard } from "../../service/get-user";

type Props = {};

const req = new CommonComponent();
const PackageApprovals = (props: Props) => {
  const [packages, setPackages] = React.useState([]);
  const [first, setFirst] = React.useState({
    show: false,
    severity: "error",
    message: "",
  });

  const navigation = useNavigate();

  React.useLayoutEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    let postObject = {
      auth: getCookie("loggedInUser"),
      search: { all: "all", status: "Awaiting Approval" },
    };

    try {
      const { returnCode, returnData }: any = await req.sendRequestToServer(
        "ProductManagement",
        "getSchoolpayPackages",
        JSON.stringify(postObject)
      );

      if (returnCode === 200) {
        setPackages(returnData.rows);
      }
    } catch (error) {
      setFirst({
        show: true,
        severity: "error",
        message: "Oops...we are having some difficulties retrieving packages",
      });
    }
  };

  const Packcolumns = [
    { header: "Package No.", accessor: "package_no" },
    { header: "Package Name", accessor: "package_name" },
    { header: "Start Date", accessor: "package_start_date" },
    { header: "End Date", accessor: "package_end_date" },
    { header: "Package Price", accessor: "package_price" },
    { header: "Grand Price", accessor: "grand_package_price" },
    { header: "Package Status", accessor: "status" },
    { header: "Package ID", accessor: "id" },
  ];

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard()) },
    { label: "Packages", route: () => navigation("/packages") },
    { label: "Package Approvals", route: () => navigation("/details") },
  ];

  const renderComponent = () => {
    return (
      <div className="-mt-10">
        <CustomBreadCrumb breadcrumbs={breadcrumbs} />
        <CustomSelectableTable
          data={packages}
          columns={Packcolumns}
          filters={[
            { label: "All", value: "All" },
            { label: "Awaiting Approval", value: "Awaiting Approval" },
            { label: "Approved", value: "Approved" },
          ]}
          Edit={(row: { id: string }) =>
            navigation("/package-details/" + row.id)
          }
          EditActionLable="Approve"
          showCheckbox={false}
          rownavigation={(row: any) => navigation("/package-details/" + row.id)}
        />
      </div>
    );
  };

  return (
    <>
      <div className="my-5">
        <div className="w-full">
          <div className="w-full py-4 px-2">{renderComponent()}</div>
        </div>
      </div>

      <CustomDialog
        open={first.show}
        message={first.message}
        onClose={() =>
          setFirst({ show: false, message: "", severity: "error" })
        }
        buttonTitle="Close"
        title="Package Approval"
        onSubmit={() =>
          setFirst({ show: false, message: "", severity: "error" })
        }
      />
    </>
  );
};

export default PackageApprovals;
