// src/redux/reducers/index.js

import { combineReducers } from "redux";
import { productsReducer } from "../reducers/productsReducer.ts";
import shoppingCartReducer from "../reducers/shopping-cart.reducer.ts";
import { userReducer } from "../reducers/userReducer.ts";

const rootReducer = combineReducers({
  cart: shoppingCartReducer, // Manages the shopping cart state
  user: userReducer, // Manages the user global state
  products: productsReducer, // Manages the products
});

export default rootReducer;
