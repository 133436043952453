import { Banknote, CreditCard, Printer, X } from "lucide-react";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { clearCart } from "../../redux/slice/cartSlice.ts";
import { addSale } from "../../test-db/index.ts";
import { CartItem } from "../../types/inventory";
import Receipt from "./Receipt.tsx";

interface PaymentModalProps {
  onClose: () => void;
  total: number;
  items: CartItem[];
}

function PaymentModal({ onClose, total, items }: PaymentModalProps) {
  const dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState<"cash" | "card">("cash");
  const [cashReceived, setCashReceived] = useState("");
  const [showReceipt, setShowReceipt] = useState(false);
  const [currentSale, setCurrentSale] = useState<any>(null);
  const receiptRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  const handlePayment = async () => {
    try {
      const sale = {
        id: crypto.randomUUID(),
        items,
        total,
        timestamp: Date.now(),
        paymentMethod,
        cashReceived:
          paymentMethod === "cash" ? parseFloat(cashReceived) : undefined,
        change:
          paymentMethod === "cash"
            ? parseFloat(cashReceived) - total
            : undefined,
      };

      await addSale(sale);
      setCurrentSale(sale);
      setShowReceipt(true);
      dispatch(clearCart());
      toast.success("Payment successful!");
    } catch (error) {
      toast.error("Payment failed. Please try again.");
    }
  };

  const change =
    paymentMethod === "cash" && cashReceived
      ? parseFloat(cashReceived) - total
      : 0;

  if (showReceipt && currentSale) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div className="bg-white rounded-lg p-6 w-full max-w-lg">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-bold">Receipt</h2>
            <div className="flex gap-2">
              <button
                onClick={handlePrint}
                className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
              >
                <Printer size={20} />
                Print
              </button>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>
          </div>
          <Receipt sale={currentSale} componentRef={receiptRef} />
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 w-96">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Payment</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="font-medium mb-2">Select Payment Method</h3>
            <div className="grid grid-cols-2 gap-4">
              <button
                onClick={() => setPaymentMethod("cash")}
                className={`flex items-center justify-center gap-2 p-3 rounded-lg border
                  ${
                    paymentMethod === "cash"
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-200"
                  }`}
              >
                <Banknote size={20} />
                Cash
              </button>
              <button
                onClick={() => setPaymentMethod("card")}
                className={`flex items-center justify-center gap-2 p-3 rounded-lg border
                  ${
                    paymentMethod === "card"
                      ? "border-blue-500 bg-blue-50"
                      : "border-gray-200"
                  }`}
              >
                <CreditCard size={20} />
                Card
              </button>
            </div>
          </div>

          {paymentMethod === "cash" && (
            <div>
              <label className="block font-medium mb-2">Cash Received</label>
              <input
                type="number"
                value={cashReceived}
                onChange={(e) => setCashReceived(e.target.value)}
                className="w-full p-2 border rounded-lg"
                placeholder="Enter amount"
              />
              {cashReceived && (
                <div className="mt-2 text-right">
                  <span className="text-gray-600">Change: </span>
                  <span
                    className={change >= 0 ? "text-green-600" : "text-red-600"}
                  >
                    ${change.toFixed(2)}
                  </span>
                </div>
              )}
            </div>
          )}

          <div className="border-t pt-4">
            <div className="flex justify-between text-lg font-bold mb-4">
              <span>Total</span>
              <span>${total.toFixed(2)}</span>
            </div>
            <button
              onClick={handlePayment}
              disabled={
                paymentMethod === "cash" &&
                (!cashReceived || parseFloat(cashReceived) < total)
              }
              className="w-full bg-blue-500 text-white py-3 rounded-lg font-medium
                disabled:bg-gray-300 disabled:cursor-not-allowed
                hover:bg-blue-600 transition-colors"
            >
              Complete Payment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
