import { ErrorMessage, Field, Form, Formik } from "formik";
import "primeicons/primeicons.css";
import { Dropdown } from "primereact/dropdown";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ButtonBase from "../../components/CustomComponents/Buttons/ButtonBase.tsx";
import ImageUpload from "../../components/CustomComponents/ImageUpload.js";
import CustomBreadCrumb from "../../components/CustomComponents/Tables/CustomBreadCrumb.tsx";
import { AppContext } from "../../context/AppContext.js";
import { routings } from "../../routes/routings.ts";
import { CommonComponent, formatAndParseNumber } from "../../service";
import { getCookie, userDashboard } from "../../service/get-user";

const initialValues = {
  packageName: "",
  packagePrice: "",
  expireDate: "",
  grandPackagePrice: 0,
  beforeEndDate: "",
  packageStartDate: "",
  packageType: "",
};

const validationSchema = Yup.object({
  packageName: Yup.string().required("Package Name is required"),
  packagePrice: Yup.string().required("Package Price is required"),
  expireDate: Yup.date()
    .required("Expire Date is required")
    .test(
      "before-end-date",
      "Expire Date must be after Before End Date",
      function (value) {
        const { beforeEndDate } = this.parent;
        return (
          !value || !beforeEndDate || new Date(value) > new Date(beforeEndDate)
        );
      }
    ),
  beforeEndDate: Yup.date()
    .required("Before End Date is required")
    .test(
      "start-date",
      "Before End Date must be after Package Start Date",
      function (value) {
        const { packageStartDate } = this.parent;
        return (
          !value ||
          !packageStartDate ||
          new Date(value) > new Date(packageStartDate)
        );
      }
    ),
  packageStartDate: Yup.date()
    .required("Package Start Date is required")
    .test(
      "start-date",
      "Package Start Date must be before Before End Date",
      function (value) {
        const { beforeEndDate } = this.parent;
        return (
          !value || !beforeEndDate || new Date(value) < new Date(beforeEndDate)
        );
      }
    ),
  packageType: Yup.string().required("Package Type is required"),
});

const AddPackage = () => {
  const commonComponent = new CommonComponent();
  const { setSnackAlert, setLoading }: any = useContext(AppContext);

  const [selectedImage, setSelectedImage] = useState("");

  const navigation = useNavigate();

  const handleSubmitForm = (values, { setSubmitting }) => {
    // Remove comma separators and parse packagePrice correctly
    const packagePrice = removeFormat(values);

    if (isNaN(packagePrice)) {
      setSnackAlert({
        open: true,
        severity: "error",
        message: "Invalid package price.",
      });
      setSubmitting(false);
      return;
    }

    const grandPackagePrice = packagePrice + Number(5000);

    const postObject = {
      auth: getCookie("loggedInUser"),
      requestData: {
        ...values,
        packagePrice, // Use parsed packagePrice
        grandPackagePrice,
        packageImage: selectedImage,
      },
    };

    setSubmitting(true);
    setLoading(true);

    commonComponent
      .sendRequestToServer(
        "ProductManagement",
        "addSchoolpayPackages",
        JSON.stringify(postObject)
      )
      .then((data) => {
        if (data.returnCode === 200) {
          setSnackAlert({
            open: true,
            severity: "success",
            message: data.returnMessage,
          });
          setTimeout(() => {
            navigation(routings.packages);
          }, 1000);
        } else {
          setSnackAlert({
            open: true,
            severity: "error",
            message: data.returnMessage,
          });
        }
      })
      .catch(() => {
        setSnackAlert({
          open: true,
          severity: "error",
          message: "Failed to Add Product",
        });
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });

    function removeFormat(values) {
      const packagePriceString = values.packagePrice.replace(/,/g, ""); // Remove commas
      const packagePrice = parseFloat(packagePriceString);
      return packagePrice;
    }
  };

  const breadcrumbs = [
    { label: "Dashboard", route: () => navigation(userDashboard()) },
    { label: "Package Management", route: () => navigation("/package") },
    { label: "Package Creation", route: () => navigation("/details") }, // Route ignored since it's the last
  ];

  const [packageTypeOptions] = useState([
    { label: "default", value: "default" },
    { label: "Schoolpay", value: "Schoolpay" },
  ]);

  return (
    <div className="mt-16">
      <div className="mx-3">
        <CustomBreadCrumb breadcrumbs={breadcrumbs} />
      </div>
      <div className="w-full flex items-center justify-center overflow-hidden -mt-4">
        <div className="w-[96%] bg-white rounded-3xl p-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitForm}
          >
            {({
              isSubmitting,
              setFieldValue,
              handleSubmit,
              values,
              errors,
              touched,
            }) => (
              <Form className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full -mt-4">
                <div className="flex flex-col">
                  <label htmlFor="packageName" className="mb-2 text-gray-700">
                    Package Name:
                  </label>
                  <Field
                    placeholder="Package Name"
                    name="packageName"
                    type="text"
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="packageName"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="packagePrice" className="mb-2 text-gray-700">
                    Package Price:
                  </label>
                  <Field
                    name="packagePrice"
                    onChange={(e) => {
                      const formattedValue = formatAndParseNumber(
                        e.target.value
                      );
                      setFieldValue("packagePrice", formattedValue);
                    }}
                    placeholder="Enter Package Price"
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="packagePrice"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                {/* Package Start Date Field */}
                <div className="flex flex-col">
                  <label
                    htmlFor="packageStartDate"
                    className="mb-2 text-gray-700"
                  >
                    Package Start Date:
                  </label>
                  <Field
                    name="packageStartDate"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                  />
                  <ErrorMessage
                    name="packageStartDate"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>

                {/* Before End Date Field */}
                <Field
                  name="beforeEndDate"
                  type="date"
                  min={
                    values.packageStartDate
                      ? new Date(
                          new Date(values.packageStartDate).setDate(
                            new Date(values.packageStartDate).getDate() + 1
                          )
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                />
                <ErrorMessage
                  name="beforeEndDate"
                  component="div"
                  className="text-red-600 mt-1"
                />

                <Field
                  name="expireDate"
                  type="date"
                  min={
                    values.beforeEndDate
                      ? new Date(
                          new Date(values.beforeEndDate).setDate(
                            new Date(values.beforeEndDate).getDate() + 1
                          )
                        )
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[--primary-color]"
                />
                <ErrorMessage
                  name="expireDate"
                  component="div"
                  className="text-red-600 mt-1"
                />

                {/* Commission Dropdown Field */}
                <div className="flex flex-col">
                  <label
                    htmlFor="grandPackagePrice"
                    className="mb-2 text-gray-700"
                  >
                    Package Type:
                  </label>
                  <Dropdown
                    id="packageType"
                    value={values.packageType}
                    options={packageTypeOptions}
                    onChange={(e) => setFieldValue("packageType", e.value)} // Ensure it's a number
                    placeholder="Select package type"
                    className={`p-dropdown text-white${
                      touched.packageType && errors.packageType && "p-invalid"
                    }`}
                  />

                  <ErrorMessage
                    name="packageType"
                    component="div"
                    className="text-red-600 mt-1"
                  />
                </div>
                {/* Image Upload Field with all the styling */}
                <ImageUpload
                  label={"upload package image"}
                  onChange={(image) => setSelectedImage(image)}
                />

                <div className="md:col-span-2 flex justify-end">
                  <div>
                    <ButtonBase
                      title={isSubmitting ? "Submitting..." : "Submit"}
                      onClick={handleSubmit}
                      className="text-white w-24"
                      theme="secondary"
                      mode="filled"
                      type="submit"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddPackage;
