import { ChevronFirst, ChevronLast, MoreVertical } from "lucide-react";
import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import blob from "../../assets/blobs/blob.svg";
import img from "../../assets/logos/pangisalogo.jpg";

import { getCookie, userDashboard } from "../../service/get-user";

type Props = {
  children: React.ReactNode | React.ReactElement<any>;
};

const SideBarContext = React.createContext({ expanded: true });

export default function SideBar({ children }: Props) {
  const [expanded, setExpanded] = React.useState(true);
  const [email, setEmail] = React.useState<string>("");
  const [username, setUsername] = React.useState<string>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [lastName, setLastName] = React.useState<string>("");

  const navigation = useNavigate();

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setExpanded(false);
      } else {
        setExpanded(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useLayoutEffect(() => {
    const auth = getCookie("loggedInUser");

    if (auth) {
      setEmail(auth.user?.emailAddress);
      setUsername(auth.user?.firstName + " " + auth.user?.lastName);
      setFirstName(auth.user?.firstName);
      setLastName(auth.user?.lastName);
    }
  }, [email, username]);

  return (
    <aside className={`h-[120vh] sticky ${expanded ? "w-64" : "w-24"} `}>
      <nav className="h-full flex flex-col bg-white border-r shadow-inner overflow-y-scroll">
        <div className="my-3 p-4 pb-2 flex justify-between items-center">
          <img
            src={img}
            alt="img"
            className={`cursor-pointer overflow-hidden transition-all ${
              expanded ? "w-24" : "w-0"
            }`}
            onClick={() => navigation(userDashboard())}
          />
          <img
            src={blob}
            alt="img"
            className={`overflow-hidden transition-all ${
              expanded ? "w-24" : "w-0"
            }`}
          />
          <button
            onClick={() => setExpanded((prev) => !prev)}
            className="p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100"
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>

        <SideBarContext.Provider value={{ expanded }}>
          <ul className="flex-1 px-3 flex flex-col">{children}</ul>
        </SideBarContext.Provider>

        <div
          className={`border-t flex p-3 fixed z-10 bottom-1 bg-white ${
            expanded ? "w-64" : "w-24"
          }`}
        >
          <div className="w-12 h-1w-12 rounded-md flex flex-row bg-[--primary-color-light] items-center justify-center p-2 ">
            <p className="text-black font-bold text-lg capitalize">
              {firstName.charAt(0)}
            </p>
            <p className="text-black font-bold text-lg capitalize">
              {lastName.charAt(0)}
            </p>
          </div>
          <div
            className={`flex justify-between items-center overflow-hidden transition-all ${
              expanded ? "w-52 ml-3" : "w-0"
            }`}
          >
            <div className="leading-4">
              <h4 className="font-semibold">{username}</h4>
              <span className="text-xs text-gray-600">{email}</span>
            </div>

            <div className="rounded-[24px] flex items-center justify-center w-12 h-12 hover:bg-[--primary-color-light] cursor-pointer">
              <MoreVertical size={20} />
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
}

type ItemProps = {
  icon: React.ReactNode | React.ReactElement<any>;
  text: string;
  active?: boolean;
  alert?: boolean; // Add alert for success/error messages if true
  children?: React.ReactNode | React.ReactElement<any>; // Submenu items
  open?: boolean; // Add open state for submenu items
  onClick?: () => void; // Add click event for submenu items
  navlink?: string;
};

export const SideBarItem = ({
  icon,
  text,
  active,
  alert,
  children,
  open,
  onClick,
  navlink,
}: ItemProps) => {
  const { expanded }: any = React.useContext(SideBarContext);

  return (
    <li className="relative group">
      {navlink ? (
        <NavLink
          to={navlink}
          onClick={onClick}
          className={`flex items-center space-x-2 py-1 px-3 font-medium rounded-md cursor-pointer transition-colors text-black hover:text-black ${
            active || open
              ? "bg-[--primary-color] text-black shadow-md"
              : "hover:bg-[--primary-color-light] text-gray-600"
          }`}
        >
          {icon}
          <span
            className={`transition-all ${
              expanded ? "w-52 ml-3" : "overflow-hidden w-0"
            }`}
          >
            {text}
          </span>
          {alert && (
            <div
              className={`absolute right-2 w-2 h-2 rounded bg-[#1e0030] ${
                expanded ? "" : "top-2"
              }`}
            />
          )}
          {!expanded && (
            <div
              className={`absolute left-full rounded-md px-3 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-0 group-hover:visible group-hover:opacity-100`}
            >
              {text}
            </div>
          )}
        </NavLink>
      ) : (
        <div
          onClick={onClick}
          className={`flex items-center space-x-2 py-1 px-3 my-2 font-medium rounded-md cursor-pointer transition-colors ${
            active || open
              ? "bg-[--primary-color] text-black shadow-md"
              : "hover:bg-[--primary-color-light] text-gray-600"
          }`}
        >
          {icon}
          <span
            className={`transition-all ${
              expanded ? "w-52 ml-3" : "overflow-hidden w-0"
            }`}
          >
            {text}
          </span>
          {alert && (
            <div
              className={`absolute right-2 w-2 h-2 rounded bg-[#1e0030] ${
                expanded ? "" : "top-2"
              }`}
            />
          )}
          {!expanded && (
            <div
              className={`absolute left-full rounded-md px-3 py-1 ml-6 bg-indigo-100 text-indigo-800 text-sm invisible opacity-0 group-hover:visible group-hover:opacity-100`}
            >
              {text}
            </div>
          )}
        </div>
      )}
      {children && (
        <ul
          className={`pl-6 transition-all  ${
            open
              ? "max-h-screen bg-[--primary-color-light]"
              : "max-h-0 overflow-hidden"
          }`}
        >
          {children}
        </ul>
      )}
    </li>
  );
};
