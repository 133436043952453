// import CryptoJS from "crypto";
import { clsx } from "clsx";
import { saveAs } from "file-saver";
import { twMerge } from "tailwind-merge";
import * as XLSX from "xlsx";
import { RemoteHelper } from "./RemoteHelper.js";
import { getCookie } from "./get-user.js";

export class CommonComponent {
  now_date = new Date();
  now_date_time = this.now_date.getTime();
  static sendRequestToServer(arg0, arg1, arg2, arg3, arg4) {
    throw new Error("Method not implemented.");
  }
  constructor() {
    this.state = {};
    this.helper = new RemoteHelper();
  }

  timeConverter(UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    const hour = a.getHours();
    const min = a.getMinutes();
    const sec = a.getSeconds();
    return date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  }

  sendRequestToServer(serviceCode, requestUrl, requestData, p0, p1) {
    return this.helper.sendPostToServer(
      serviceCode + "/" + requestUrl,
      requestData
    );
  }

  sendUploadRequestToServer(
    serviceCode,
    requestUrl,
    requestData,
    blockui,
    responseHandler,
    errorHandler
  ) {
    return this.helper.sendPostWithUpload(
      serviceCode + "/" + requestUrl,
      requestData
    );
  }

  validateResponse(response) {
    if (!response) {
      this.showDialog({ title: "Failed", message: "no response from server" });
      return false;
    }
    if (response.returnCode) {
      this.showDialog({ title: "Failed", message: response.returnMessage });
      return false;
    }
    return true;
  }

  formatAmount(amount) {
    return Number(amount).toLocaleString("en");
  }

  formatCurrency(id) {
    document.getElementById(id).addEventListener(
      "input",
      (event) =>
        // @ts-ignore
        (event.target.value = (
          parseInt(event.target.value.replace(/[^\d]+/gi, "")) || 0
        ).toLocaleString("en-US"))
    );
  }

  removeCommas(regXpPattern, amount) {
    if (regXpPattern.test(amount) === true) {
      return amount.replace(/,/g, "");
    }
    return amount;
  }

  randomPasswordGenerator(keyLength) {
    let i,
      newPassWord = "",
      characters =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    let charactersLength = characters.length;

    for (i = 0; i < keyLength; i++) {
      newPassWord += characters.substr(
        Math.floor(Math.random() * charactersLength + 1),
        1
      );
    }
    return newPassWord;
  }

  /**
   * Test Reusable PostObj to send to Server
   * */
  customPostObject(id = null, search = {}, requestData = {}) {
    return {
      auth: getCookie("loggedInUser"),
      id: id,
      search: {},
      requestData: requestData,
    };
  }

  /**
   * Encryption And Decryption Methods
   * @author Buyinza Briton
   */

  // encryptWithString = (text) => {
  //   const passphrase = "Configuration Re-imagined";
  //   return CryptoJS.AES.encrypt(text, passphrase).toString();
  // };

  // decryptStringToOriginal = (ciphertext) => {
  //   const passphrase = "Configuration Re-imagined";
  //   const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  //   const originalText = bytes.toString(CryptoJS.enc.Utf8);
  //   return originalText;
  // };

  base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    b64Data = b64Data.replace(/\s/g, ""); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  downloadFile(b64encodedString, fileName, contentType) {
    if (b64encodedString) {
      const blob = this.base64ToBlob(b64encodedString, contentType);
      saveAs(blob, fileName);
    }
  }

  exportToExcel = (data, filename = "data.xlsx") => {
    const ws = XLSX.utils.json_to_sheet(data); // Convert JSON to sheet
    const wb = XLSX.utils.book_new(); // Create a new workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1"); // Append the sheet to the workbook
    XLSX.writeFile(wb, filename); // Trigger the download
  };
}

export const convertCurrency = (item, currency = "UGX") => {
  // Ensure item is a string, then remove commas and convert to a number
  const amount = Number(String(item).replace(/,/g, ""));

  if (isNaN(amount)) {
    throw new Error(
      "Invalid input: item must be a valid number or numeric string"
    );
  }

  const response = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(amount);

  return response;
};

export const formatAndParseNumber = (value) => {
  // Remove existing commas for consistent formatting.
  const sanitizedValue = String(value).replace(/,/g, "");

  // Split the value into integer and decimal parts.
  const [integerPart, decimalPart] = sanitizedValue.split(".");

  // Format only the integer part with commas.
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // If there's a decimal part, include it, even if it's just the decimal point.
  return decimalPart !== undefined
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
};

/**
 * Phone Number Validation Regex
 * @author Buyinza B
 * */
export const isValidPhoneNumber = (phone) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/; // E.164 format
  return phoneRegex.test(phone);
};

/**
 * Email Validation Regex
 * @author Buyinza B
 * */
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // RFC 5322 compliant
  return emailRegex.test(email);
};

/**
 * Password Validation Regex
 * @author Buyinza B
 * */
export const isValidPassword = (password) => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/; // 8 characters, at least one digit, one uppercase letter, one lowercase letter, and one special character
  return passwordRegex.test(password);
};

/**
 * Password Complexity Score
 * @author Buyinza B
 * */
export const calculatePasswordComplexity = (password) => {
  let score = 0;

  // Length
  if (password.length >= 8) {
    score += 10;
  }

  // Digits
  if (/\d/.test(password)) {
    score += 20;
  }

  // Uppercase
  if (/[A-Z]/.test(password)) {
    score += 20;
  }

  // Lowercase
  if (/[a-z]/.test(password)) {
    score += 20;
  }

  // Special characters
  if (/[!@#$%^&*]/.test(password)) {
    score += 20;
  }

  return score;
};

/**
 * Password Strength Indicator
 * @author Buyinza B
 * */
export const calculatePasswordStrength = (password) => {
  let strength = "Weak";

  if (calculatePasswordComplexity(password) >= 80) {
    strength = "Very Strong";
  } else if (calculatePasswordComplexity(password) >= 60) {
    strength = "Strong";
  } else if (calculatePasswordComplexity(password) >= 40) {
    strength = "Moderate";
  } else {
    strength = "Weak";
  }

  return strength;
};

/**
 * Form Validation
 * @author Buyinza B
 * */
export const validateForm = (form) => {
  let isValid = true;

  Object.values(form).forEach((field) => {
    if (field.required && field.value === "") {
      field.error = "This field is required";
      isValid = false;
    } else if (field.pattern && !field.pattern.test(field.value)) {
      field.error = field.errorMessage;
      isValid = false;
    } else {
      field.error = "";
    }
  });

  return isValid;
};

/**
 * Generate Unique ID
 * @author Buyinza B
 * */
export const generateUniqueId = () => {
  return Math.floor(Math.random() * 10000000000000000) + 1;
};

/**
 * Convert Date to Localized String
 * @author Buyinza B
 * */
export const formatDate = (date, format = "YYYY-MM-DD") => {
  if (!date) return "";

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

/**
 * Convert Localized String to Date
 * @author Buyinza B
 * */
export const parseDate = (dateString, format = "YYYY-MM-DD") => {
  // const options = {
  //   year: "numeric",
  //   month: "2-digit",
  //   day: "2-digit",
  // };

  const parsedDate = new Date(Date.parse(dateString));
  return parsedDate.getTime() === NaN ? null : parsedDate;
};

/**
 * Display alert only when in development environment
 * @author Buyinza B
 */
export const logAlert = (message) => {
  if (process.env.NODE_ENV !== "production") {
    if (typeof message !== String) {
      alert(
        JSON.stringify(message) + " (typeof message: " + typeof message + ")"
      );
    } else {
      alert(message);
    }
  }
};

/**
 * Currency Formatter
 * @author Buyinza Briton
 * */
const UGX_RATE = 3800; // Example rate: 1 USD = 3800 UGX

export const toUGX = (usd) => {
  return usd * UGX_RATE;
};

export const formatUGX = (amount) => {
  return new Intl.NumberFormat("en-UG", {
    style: "currency",
    currency: "UGX",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function formatCurrency(amount, currency = "UGX") {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
  }).format(amount);
}
